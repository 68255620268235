import React from "react";
import Slide from "../components/homeSlide";
import NavBar from "../components/navbar";
import Release from "../components/release";
import Footer from "../components/footer";
import About from "../components/about";
import Upcoming from "../components/upcoming";
import Gallery from "../components/gallery";

const Home = () => {
  return (
    <div>
      <NavBar />
      <Slide />
      <About />
      <Gallery />
      <Release channelId="UCEePaz-0hozUGnbqQAGxvGA" />
      <Upcoming />

      <Footer />
    </div>
  );
};

export default Home;
