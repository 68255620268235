import React from "react";

import { BsInstagram, BsSnapchat, BsSpotify, BsYoutube } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <div
      className="py-10 text-white"
      style={{
        background: "#403234",
      }}
    >
      <div className=" items-start justify-center grid md:grid-cols-2">
        <div className="w-full flex justify-start ">
          <div className="ml-10">
            <div className="w-28 flex items-center ">
              <img src="logo.png" alt="" className=" W-full object-cover " />
            </div>

            <div className="text-left ">
              <div className="text-lg black font-bold uppercase text-left">
                Email
              </div>
              <a
                href="mailto:rjthebeastdj@gmail.com"
                target="_blank"
                rel="noreferrer"
                className="text-sm my-1"
              >
                rjthebeastdj@gmail.com
              </a>
              <div className="text-sm text-left my-1">
                <i>Aspiring to be the best DJ</i>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-start md:justify-end md:pr-20 pl-10">
          <div>
            <div className=" mt-16 ">
              <div className="text-lg black font-bold uppercase text-left">
                NAVIGATIONS
              </div>
              <div className="text-lg flex flex-col w-full items-start ">
                <NavLink to="/" className="text-sm my-1 hover:text-yellow-500">
                  HOME
                </NavLink>
                <NavLink to="/" className="text-sm my-1 hover:text-yellow-500">
                  ABOUT
                </NavLink>
                <NavLink to="/" className="text-sm my-1 hover:text-yellow-500">
                  WORK
                </NavLink>
                <NavLink to="/" className="text-sm my-1 hover:text-yellow-500">
                  EVENTS
                </NavLink>
                <NavLink to="/" className="text-sm my-1 hover:text-yellow-500">
                  INTERVIEW
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center mt-10">
        <div>
          <div className="text-2xl  font-medium">Let us Connect</div>
          <div className="flex flex-wrap my-4 rounded-2xl p-2">
            <a
              href="https://www.instagram.com/romyjons/"
              target="_blank"
              rel="noreferrer"
              className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
            >
              <BsInstagram size={20} />
            </a>

            <a
              href="https://www.snapchat.com/add/romyjones?share_id=0RYAdb_aRPeQm3XiH_F7TA&locale=en_CN"
              target="_blank"
              rel="noreferrer"
              className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
            >
              <BsSnapchat size={20} />
            </a>

            <a
              href="https://www.tiktok.com/@romyjons_tz"
              target="_blank"
              rel="noreferrer"
              className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
            >
              <FaTiktok size={20} />
            </a>

            <a
              href="https://www.youtube.com/channel/UCEePaz-0hozUGnbqQAGxvGA"
              target="_blank"
              rel="noreferrer"
              className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
            >
              <BsYoutube size={20} />
            </a>
            <a
              href="https://open.spotify.com/artist/2NHoDHvuRPKvtarjNsWuLP?si=BUyhyD4zTqaMJvO_Zm623A"
              target="_blank"
              rel="noreferrer"
              className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
            >
              <BsSpotify size={20} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
