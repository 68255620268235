import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import SwiperCore, { Navigation } from "swiper/core";
import { Pagination } from "swiper";
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const Upcoming = () => {
  const data = [
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEh9Oy_w1RtIwm1qGzMwuAKh8Gastk6CoaIdpovxseu9zfxrR_1Ds2ijYGVH0DYufoYIxN9qTPVn67NoKmuE2rzcfe7i1mTe1S_x4zmS9XxyK36nhKA0x8ShAshvvmLWzYS1OIuA6I9-JxbHPP5eTC81sYW17l-VATO2MKT-X0nEBx1xpopJ0ox5Wlm5bdg/w640-h640/WhatsApp%20Image%202024-12-07%20at%2018.14.39.jpeg",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiHJv0T-b79bhwp9tUJDh_NtDsrj3QIKWQeMtfyZQ6as_MDEZMLnubsKXBPU-U0Rw42vRaXS4457ZrXZZVdnFeJWNfcWqDWVIvBPlIcNl20FzJbSQTy5iAPSny3I9bClgd4D1wERUIqHQ2uGLjU1ziKaD_DgSE4sp9wuR3LG_KCmy9ow7og112vnmXMww0/w512-h640/WhatsApp%20Image%202024-01-28%20at%2018.55.13.jpeg",
    },

    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgy6Dx_DKy831WMdx-DPK3WA04iJ76P2psZC6lC_WyjUNpgzmE1zlNI_NGmuq0vbG76njCcukKhhok5AFG4GfkCFB0mH1pDy0AO80DJRSi6_nw6VNtfjQ7fRh3FFj7LGvfux7l4cZ7G0dJ9oY7FQraZfyWiMY6c0IitNYeKdhRV0csuZHPGArYM_SKfW6E/w512-h640/WhatsApp%20Image%202024-04-20%20at%2007.59.42.jpeg",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEh6TSUxh4tqHNq0PlaPyyjDb33ntNaTdU5hwyP8wK1QVP9v1VXwDzi-MT3kNYLGqN-dbkboEzZsweAMaWC6w7iFmRc3y1HGCSFVD1ELLW7t92OCyUgLnniQZjxbwQtOVhn6yIM9AAq58xj6V1X3yE1XO_dKSyThR3x8lmJSrRhJoSpQTQ49sYzxSBV5vf8/w512-h640/WhatsApp%20Image%202024-04-20%20at%2008.00.20.jpeg",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiJj4s1zD943k-I8Ami1aGboXktJILXdDBaOjwEkQ1EkqAUhVvkxRhyphenhyphennDOAEFMONB4Eezhy7l9KIaZs2aGwIpqjCngxaaTqnchmmbsF-xFqyPbMKMcKZkub3S4-FZQt4REQQUrofyEynx5o3XLdgTpCAsiyI9zJ9tU6eTjpB12_4FvHYUCaQWYQhkCT8Zg/w512-h640/WhatsApp%20Image%202024-05-27%20at%2013.22.02.jpeg",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhQTsvmxmZFEWWNIEf0lav-KiNGLvNAcUxzVl8yUjdbJ7iZkcRH2aOuVj1o9jIlouApYAb7s4QQbwT-GReoi2e2vMowGe5j1mY_kO1Q26fSnR6zZkRNyoOlPownX1DDkhWFR8ybdYEpwacPpaa0jRIb5IDFQnBdtr4bBwmKH7nKthyphenhyphenxEYPj-Y6rfclnSws/w512-h640/WhatsApp%20Image%202024-06-01%20at%2010.27.53.jpeg",
    },
  ];
  return (
    <div
      style={{
        background: "#E2C2B3",
      }}
      id="events"
      className="w-full flex flex-col items-center"
    >
      <div className=" w-full flex  flex-col items-center pt-20  ">
        <div
          className="font-base pt-10 justify-center black flex  gotham md:text-5xl text-3xl pb-8 text-white"
          data-aos="fade-up"
        >
          Upcoming Events
        </div>
        <div className="lg:w-3/4 w-full 2xl:w-2/4">
          <div className="w-full mySlider   overflow-hidden">
            <Swiper
              slidesPerView={2}
              loop={true}
              autoplay={true}
              className="w-full mb-10"
            >
              {data.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className=" md:w-full mx-2 rounded-xl border border-black/60 overflow-hidden"
                >
                  <div className="text-white  md:w-full bg-black/10">
                    <div className="flex md:w-full" data-aos="zoom-in">
                      <img
                        src={item.thumbnail}
                        alt="thumbnail"
                        className="md:w-full"
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upcoming;
