import React, { useState, useEffect } from "react";

const Slide = () => {
  const [firstAnime, setFirstAnime] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setTimeout(() => {
        setFirstAnime(true);
      }, 300);
      setTimeout(() => {
        setFirstAnime(false);
      }, 5000);
    }, 6000);
  }, []);

  return (
    <div className="" id="home">
      <div className="mySwiper">
        {/* for desktop */}
        <div
          className="relative hidden md:block w-full h-screen"
          style={{
            backgroundImage: `url('https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg6oF26rMslRm-sfuSb8GROeo6y1k-k23R0gGHel9rU4uzBc0Pi-jjk8ECco2ysGsk6XNK5NrJ3AUQyr40038D3gdkUIzvjnnoXcqn9fEWs3Db8ENOWeJbQcdKOJ9V8Z8XREPFyXy9CuygIRmPiWQzYQBCFMp6JE8GjRr4VTUKUELr7hs7awbMBkqEceMo/s16000/IMG_2211%202.jpg')`,
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
          }}
        >
          {/* <img
            src=""
            alt="thumbnail"
            style={{ height: "100vh", width: "100%", objectFit: "cover" }}
          /> */}
          <div
            className="w-full flex flex-col items-center md:pl-10   justify-end pb-20  h-screen absolute top-0"
            style={{
              background: "linear-gradient(rgba(0,0,0,0), black)",
            }}
          >
            <div className="md:w-1/2 w-full  px-4 md:mx-0 flex flex-col items-center   justify-center">
              <div className=" overflow-hidden text-center">
                <div
                  className="font-black black text-5xl text-gray-300 play"
                  style={{
                    transition: ".5s",
                    transform: firstAnime
                      ? "translateY(0px)"
                      : "translateY(100px)",
                  }}
                >
                  <img
                    src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhIS8T1UoRoBoKGZwkY-d9SoBHTkRDQHl_Kl54tx2b9OlJf1Rv51S1D8j1OVocWq64HDbozmL34HPEtXFc0GKil5-yMPZ5OjSwVuzRB4hFDTu-3f-BhU0_t46vnjW-9zijqk4kVVYZV6xwghdpCs3iRn3avcnQYz7FrXkeFtJECf7bCwk9NWomrKCIT370/s16000/another2.png"
                    alt="name_logo"
                    className="h-24"
                  />
                </div>
              </div>

              <div className="h-35 mt-4 overflow-hidden">
                <div
                  className="text-lg text-white text-center"
                  style={{
                    transition: ".5s",
                    transform: firstAnime
                      ? "translateY(0px)"
                      : "translateY(-110px)",
                  }}
                >
                  <b className="black">A MULTITALENTED PERSON</b>
                  <br />
                  <i>
                    "Use your unique gifts and talents to make a difference in
                    the world."
                  </i>
                </div>
              </div>

              <div
                className="flex justify-between items-center text-white mb-5 mt-4"
                style={{
                  width: !firstAnime ? 0 : 200,
                  transition: ".5s",
                }}
              >
                <div
                  className=" w-full"
                  style={{
                    background: "#687477",
                    height: 2,
                  }}
                />
                <div
                  className=" w-full "
                  style={{
                    background: "#E2C2B3",
                    height: 2,
                  }}
                />
                <div
                  className=" w-full "
                  style={{
                    background: "#403234",
                    height: 2,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* for desktop */}
        {/* for mobile */}
        <div className="relative md:hidden">
          <img
            src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg6oF26rMslRm-sfuSb8GROeo6y1k-k23R0gGHel9rU4uzBc0Pi-jjk8ECco2ysGsk6XNK5NrJ3AUQyr40038D3gdkUIzvjnnoXcqn9fEWs3Db8ENOWeJbQcdKOJ9V8Z8XREPFyXy9CuygIRmPiWQzYQBCFMp6JE8GjRr4VTUKUELr7hs7awbMBkqEceMo/s16000/IMG_2211%202.jpg"
            alt="thumbnail"
            style={{ height: "100vh", width: "100%", objectFit: "cover" }}
          />
          <div
            className="w-screen flex flex-col items-center md:pl-10   justify-end pb-20  h-screen absolute top-0"
            style={{
              background: "linear-gradient(rgba(0,0,0,0), black)",
            }}
          >
            <div className="md:w-1/2 w-full  px-4 md:mx-0 flex flex-col items-center   justify-center">
              <div className=" overflow-hidden text-center">
                <div className="font-black black text-5xl text-gray-300 play">
                  <img
                    src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhIS8T1UoRoBoKGZwkY-d9SoBHTkRDQHl_Kl54tx2b9OlJf1Rv51S1D8j1OVocWq64HDbozmL34HPEtXFc0GKil5-yMPZ5OjSwVuzRB4hFDTu-3f-BhU0_t46vnjW-9zijqk4kVVYZV6xwghdpCs3iRn3avcnQYz7FrXkeFtJECf7bCwk9NWomrKCIT370/s16000/another2.png"
                    alt="name_logo"
                    className="h-24"
                  />
                </div>
              </div>

              <div className="h-35 mt-4 overflow-hidden w-full text-center ">
                <div className="text-lg text-white text-center">
                  <b className="black">A MULTITALENTED PERSON</b>
                  <br />
                  <i>
                    "Use your unique gifts and talents to make a difference in
                    the world."
                  </i>
                </div>
              </div>

              <div
                className="flex justify-between items-center text-white mb-5 mt-4"
                style={{
                  width: !firstAnime ? 0 : 200,
                  transition: ".5s",
                }}
              >
                <div
                  className=" w-full"
                  style={{
                    background: "#687477",
                    height: 2,
                  }}
                />
                <div
                  className=" w-full "
                  style={{
                    background: "#E2C2B3",
                    height: 2,
                  }}
                />
                <div
                  className=" w-full "
                  style={{
                    background: "#403234",
                    height: 2,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /for mobile */}
      </div>
    </div>
  );
};

export default Slide;
