import React from "react";
import {
  BsInstagram,
  BsClockHistory,
  BsSpotify,
  BsYoutube,
} from "react-icons/bs";
import { FaFacebookF, FaTiktok } from "react-icons/fa";
import { GiMusicalNotes, GiTrophy } from "react-icons/gi";
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import Swiper core and required modules
import SwiperCore, { EffectCards, Autoplay } from "swiper";

// install Swiper modules
SwiperCore.use([EffectCards, Autoplay]);

const About = () => {
  AOS.init({
    duration: 1000,
  });
  return (
    <div
      className="md:py-24  flex flex-col bg-blue-500 items-center justify-center"
      id="about"
      style={{
        backgroundImage: `url(${"https://1.bp.blogspot.com/-VlJCR7L0Tqs/YO7wOdIoMJI/AAAAAAAABTo/DXjz6fJmnd8w2LaPGO0rOK773iQhot6EQCLcBGAsYHQ/s16000/Screenshot%2B2021-07-14%2Bat%2B17.09.37.png"})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundBlendMode: "multiply",
      }}
    >
      <div className="w-full md:w-3/4 2xl:w-2/4 text-white">
        {/* mobile version */}
        <div
          align="justify"
          className=" md:mx-0 pb-10 md:hidden lg:pb-0 w-full md:w-2/3 px-4"
          data-aos="fade-up"
        >
          <div
            className="font-bold black text-3xl md:text-5xl lg:py-8 "
            data-aos="zoom-in"
          >
            About Me
          </div>
          Romeo George, professionally known as “RJ The DJ”, has been
          entertaining the masses for almost two decades. He professionally
          began in 2008 as a Radio and Television Presenter with Clouds Media,
          one of Tanzania’s biggest and most popular media houses.
          <br />
          <br />
          Born on the 16th of August 1988, Romeo joined local media as a Radio
          and Television Presenter at a young age. He quickly mastered the
          position and was eager to expand his career to other parts of the
          industry. On his free time, Romeo took it upon himself to shadow more
          seasoned entertainers thus learning from some of the best in the
          industry then. He is self-taught on the turntables as well as in using
          the various modern Deejaying applications.
          <br />
          <br />
          RJ’s passion for Deejaying and entertaining the masses has seen him
          become a phenomenal all rounded Entertainer. RJ is the Official DJ for
          Diamond Platnumz, Tanzania’s biggest musical export to date. He has
          opened and played with Diamond all over the world in various countries
          in Africa, Europe, The Middle East, Europe and Asia. RJ has also
          played as a solo Entertainer performing for crowds in clubs, arenas
          and stadiums alike, amassing a cult-like following while at it. Romeo
          believes in playing half as hard as he works and so aspires to be
          counted as one of the best DJs in the world. He has won numerous
          awards including but not limited to “Best DJ” at the Bongo Music
          Awards, AEA USA Awards, EAEA Awards, Zikomo Awards to mention but a
          few.
          <br />
          <br />
          RJ added another cap to his career by acting in various local shows in
          Tanzania. His biggest and longest standing role was as “Bill Junior”,
          a character on the popular Tanzanian TV Show “Jua Kali”. He has played
          various roles and stayed consistent on Television screens across
          Tanzania and Africa in general. Romeo has always had a heart for the
          underprivileged in society. He is especially drawn to assisting the
          physically challenged, women and children in Tanzania. It is for this
          reason that in 2022 he started his own Foundation, “Jamii Moja”. The
          Foundation, for which Romeo is also the CEO, aims to assist the needy
          with basic needs including but not limited to food, shelter, health
          provisions and education in safe and peaceful environments.
          <br />
          <br />
          <a href="https://jamiimoja.co.tz/" className="text-blue-500">
            https://jamiimoja.co.tz/
          </a>
          <div className="md:w-3/4 w-full px-4 md:px-0 text-white flex flex-col items-start my-4">
            <div className="text-lg font-bold">
              Connect with the <b className="black">Romy Jons</b>
            </div>
            <div className="flex flex-wrap my-4 bg-white bg-opacity-10 rounded-2xl p-2">
              <a
                href="https://www.instagram.com/romyjons/"
                target="_blank"
                rel="noreferrer"
                className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
              >
                <BsInstagram size={20} />
              </a>

              <a
                href="https://www.facebook.com/people/Romyjons/100046653473487/"
                target="_blank"
                rel="noreferrer"
                className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
              >
                <FaFacebookF size={20} />
              </a>

              <a
                href="https://www.tiktok.com/@romy_jons?lang=en"
                target="_blank"
                rel="noreferrer"
                className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
              >
                <FaTiktok size={20} />
              </a>

              <a
                href="https://www.youtube.com/channel/UCEePaz-0hozUGnbqQAGxvGA"
                target="_blank"
                rel="noreferrer"
                className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
              >
                <BsYoutube size={20} />
              </a>
              <a
                href="https://open.spotify.com/artist/2NHoDHvuRPKvtarjNsWuLP?si=BUyhyD4zTqaMJvO_Zm623A"
                target="_blank"
                rel="noreferrer"
                className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
              >
                <BsSpotify size={20} />
              </a>
            </div>
          </div>
          <div className="flex flex-wrap justify-start">
            <div className="flex flex-col w-full my-2 items-center md:mx-2 bg-red-300 backdrop-blur bg-opacity-10 p-2 rounded-xl">
              <BsClockHistory size={30} />
              <div
                className="text-red-500"
                style={{
                  color: "#E2C2B3",
                }}
              >
                Year Started
              </div>
              <div className="black text-white bg-white bg-opacity-20 px-4  py-1 rounded-xl">
                2010
              </div>
            </div>

            <div className="flex flex-col w-full my-2 items-center md:mx-2  bg-red-300 backdrop-blur bg-opacity-10 p-2 rounded-xl">
              <GiMusicalNotes size={30} />
              <div
                className="text-red-500"
                style={{
                  color: "#E2C2B3",
                }}
              >
                Music Type
              </div>
              <div className="black text-white bg-white bg-opacity-20 px-4  py-1 rounded-xl">
                URBAN STYLE
              </div>
            </div>
            <div className="flex flex-col w-full my-2 items-center md:mx-2  bg-red-300 backdrop-blur bg-opacity-10 p-2 rounded-xl">
              <GiTrophy size={30} />
              <div
                className="text-red-500"
                style={{
                  color: "#E2C2B3",
                }}
              >
                Award
              </div>
              <div className="black text-white bg-white bg-opacity-20 px-4  py-1 rounded-xl">
                AEAUSA 2018 WINNER
              </div>
            </div>
            <div className="flex flex-col w-full my-2 items-center md:mx-2  bg-red-300 backdrop-blur bg-opacity-10 p-2 rounded-xl">
              <GiTrophy size={30} />
              <div
                className="text-red-500"
                style={{
                  color: "#E2C2B3",
                }}
              >
                Award
              </div>
              <div className="black text-white bg-white bg-opacity-20 px-4  py-1 rounded-xl">
                EAEA 2022 WINNER
              </div>
            </div>
            <div className="flex flex-col w-full my-2 items-center md:mx-2  bg-red-300 backdrop-blur bg-opacity-10 p-2 rounded-xl">
              <GiTrophy size={30} />
              <div
                className="text-red-500"
                style={{
                  color: "#E2C2B3",
                }}
              >
                Award
              </div>
              <div className="black text-white bg-white bg-opacity-20 px-4  py-1 rounded-xl">
                ZIKOMO 2022 WINNER
              </div>
            </div>
            <div className="flex flex-col w-full my-2 items-center md:mx-2  bg-red-300 backdrop-blur bg-opacity-10 p-2 rounded-xl">
              <GiTrophy size={30} />
              <div
                className="text-red-500"
                style={{
                  color: "#E2C2B3",
                }}
              >
                Award
              </div>
              <div className="black text-white bg-white bg-opacity-20 px-4  py-1 rounded-xl">
                BONGO MUSIC AWARD
              </div>
            </div>
            <div className="flex flex-col w-full my-2 items-center md:mx-2  bg-red-300 backdrop-blur bg-opacity-10 p-2 rounded-xl">
              <GiTrophy size={30} />
              <div
                className="text-red-500"
                style={{
                  color: "#E2C2B3",
                }}
              >
                Award
              </div>
              <div className="black text-white bg-white bg-opacity-20 px-4  py-1 rounded-xl">
                FILM FESTIVAL AWARD 2023
              </div>
            </div>
          </div>
        </div>
        {/* mobile version */}
        {/* desktop version */}
        <div className=" w-full  ">
          <div
            align="justify"
            className="mx-4 md:mx-0 hidden md:block pb-10 lg:pb-0 w-full lg:max-w-[1020px] px-4"
            data-aos="fade-up"
          >
            <div
              className="font-bold black text-3xl md:text-5xl lg:pb-8 "
              data-aos="fade-up"
            >
              About Me
            </div>
            Romeo George, professionally known as “RJ The DJ”, has been
            entertaining the masses for almost two decades. He professionally
            began in 2008 as a Radio and Television Presenter with Clouds Media,
            one of Tanzania’s biggest and most popular media houses.
            <br />
            <br />
            Born on the 16th of August 1988, Romeo joined local media as a Radio
            and Television Presenter at a young age. He quickly mastered the
            position and was eager to expand his career to other parts of the
            industry. On his free time, Romeo took it upon himself to shadow
            more seasoned entertainers thus learning from some of the best in
            the industry then. He is self-taught on the turntables as well as in
            using the various modern Deejaying applications.
            <br />
            <br />
            RJ’s passion for Deejaying and entertaining the masses has seen him
            become a phenomenal all rounded Entertainer. RJ is the Official DJ
            for Diamond Platnumz, Tanzania’s biggest musical export to date. He
            has opened and played with Diamond all over the world in various
            countries in Africa, Europe, The Middle East, Europe and Asia. RJ
            has also played as a solo Entertainer performing for crowds in
            clubs, arenas and stadiums alike, amassing a cult-like following
            while at it. Romeo believes in playing half as hard as he works and
            so aspires to be counted as one of the best DJs in the world. He has
            won numerous awards including but not limited to “Best DJ” at the
            Bongo Music Awards, AEA USA Awards, EAEA Awards, Zikomo Awards to
            mention but a few.
            <br />
            <br />
            RJ added another cap to his career by acting in various local shows
            in Tanzania. His biggest and longest standing role was as “Bill
            Junior”, a character on the popular Tanzanian TV Show “Jua Kali”. He
            has played various roles and stayed consistent on Television screens
            across Tanzania and Africa in general. Romeo has always had a heart
            for the underprivileged in society. He is especially drawn to
            assisting the physically challenged, women and children in Tanzania.
            It is for this reason that in 2022 he started his own Foundation,
            “Jamii Moja”. The Foundation, for which Romeo is also the CEO, aims
            to assist the needy with basic needs including but not limited to
            food, shelter, health provisions and education in safe and peaceful
            environments.
            <br />
            <br />
            <a href="https://jamiimoja.co.tz/" className="text-blue-500">
              https://jamiimoja.co.tz/
            </a>
            <div className="md:w-3/4 w-full px-4 md:px-0 text-white flex flex-col items-start my-4">
              <div className="text-lg font-bold" data-aos="zoom-in">
                Connect with the <b className="black">Romy Jons</b>
              </div>
              <div
                className="flex flex-wrap my-4 bg-white bg-opacity-10 rounded-2xl p-2"
                data-aos="zoom-out"
              >
                <a
                  href="https://www.instagram.com/romyjons/"
                  target="_blank"
                  rel="noreferrer"
                  className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
                  data-aos="zoom-in"
                >
                  <BsInstagram size={20} />
                </a>

                <a
                  href="https://www.facebook.com/people/Romyjons/100046653473487/"
                  target="_blank"
                  rel="noreferrer"
                  className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
                  data-aos="zoom-in"
                >
                  <FaFacebookF size={20} />
                </a>

                <a
                  href="https://www.tiktok.com/@romy_jons?lang=en"
                  target="_blank"
                  rel="noreferrer"
                  className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
                  data-aos="zoom-in"
                >
                  <FaTiktok size={20} />
                </a>

                <a
                  href="https://www.youtube.com/channel/UCEePaz-0hozUGnbqQAGxvGA"
                  target="_blank"
                  rel="noreferrer"
                  data-aos="zoom-in"
                  className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
                >
                  <BsYoutube size={20} />
                </a>
                <a
                  href="https://open.spotify.com/artist/2NHoDHvuRPKvtarjNsWuLP?si=BUyhyD4zTqaMJvO_Zm623A"
                  target="_blank"
                  data-aos="zoom-in"
                  rel="noreferrer"
                  className="h-10 w-10 flex m-1 justify-center items-center bg-white bg-opacity-10 rounded-full "
                >
                  <BsSpotify size={20} />
                </a>
              </div>
            </div>
            <div className="flex flex-wrap justify-start">
              <div
                data-aos="zoom-out"
                className="flex flex-col items-center md:mx-2 bg-red-300 backdrop-blur bg-opacity-10 p-2 rounded-xl"
              >
                <BsClockHistory size={30} d />
                <div
                  className="text-red-500"
                  style={{
                    color: "#E2C2B3",
                  }}
                  data-aos="zoom-out"
                >
                  Year Started
                </div>
                <div
                  className="black text-white bg-white bg-opacity-20 px-4  py-1 rounded-xl"
                  data-aos="zoom-in"
                >
                  2010
                </div>
              </div>

              <div
                data-aos="zoom-out"
                className="flex flex-col items-center md:mx-2  bg-red-300 backdrop-blur bg-opacity-10 p-2 rounded-xl"
              >
                <GiMusicalNotes size={30} />
                <div
                  className="text-red-500"
                  style={{
                    color: "#E2C2B3",
                  }}
                  data-aos="zoom-out"
                >
                  Music Type
                </div>
                <div
                  className="black text-white bg-white bg-opacity-20 px-4  py-1 rounded-xl"
                  data-aos="zoom-in"
                >
                  URBAN STYLE
                </div>
              </div>
              <div
                data-aos="zoom-out"
                className="flex flex-col items-center md:mx-2  bg-red-300 backdrop-blur bg-opacity-10 p-2 rounded-xl"
              >
                <GiTrophy size={30} />
                <div
                  className="text-red-500"
                  style={{
                    color: "#E2C2B3",
                  }}
                  data-aos="zoom-out"
                >
                  Award
                </div>
                <div
                  className="black text-white bg-white bg-opacity-20 px-4  py-1 rounded-xl"
                  data-aos="zoom-in"
                >
                  ZIKOMO 2018 WINNER
                </div>
              </div>
              <div
                data-aos="zoom-out"
                className="flex flex-col items-center md:mx-2 my-2  bg-red-300 backdrop-blur bg-opacity-10 p-2 rounded-xl"
              >
                <GiTrophy size={30} />
                <div
                  className="text-red-500"
                  style={{
                    color: "#E2C2B3",
                  }}
                  data-aos="zoom-out"
                >
                  Award
                </div>
                <div
                  className="black text-white bg-white bg-opacity-20 px-4  py-1 rounded-xl"
                  data-aos="zoom-in"
                >
                  BONGO MUSIC AWARDS
                </div>
              </div>
              <div
                data-aos="zoom-out"
                className="flex flex-col my-2 items-center md:mx-2  bg-red-300 backdrop-blur bg-opacity-10 p-2 rounded-xl"
              >
                <GiTrophy size={30} />
                <div
                  className="text-red-500"
                  style={{
                    color: "#E2C2B3",
                  }}
                  data-aos="zoom-out"
                >
                  Award
                </div>
                <div
                  className="black text-white bg-white bg-opacity-20 px-4  py-1 rounded-xl"
                  data-aos="zoom-in"
                >
                  EAEA 2022 WINNER
                </div>
              </div>
              <div
                c
                data-aos="zoom-out"
                className="flex flex-col my-2 items-center md:mx-2  bg-red-300 backdrop-blur bg-opacity-10 p-2 rounded-xl"
              >
                <GiTrophy size={30} />
                <div
                  className="text-red-500"
                  style={{
                    color: "#E2C2B3",
                  }}
                >
                  Award
                </div>
                <div className="black text-white bg-white bg-opacity-20 px-4  py-1 rounded-xl">
                  FILM FESTIVAL AWARD 2023
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* desktop version */}
      </div>
    </div>
  );
};

export default About;
